<template>
  <div class="form-elements">
    <vuestic-widget>
      <template slot="header">
        <div class="d-flex align-items-center justify-content-between" style="width: 100%">
          <span class="title">Subscription Plans</span>
          <div class="action-div">
            <router-link v-if="hasAgencyStripeEnabled" :to="{ name: 'agency.plans.create' }">
              <button class="btn btn-default btn-primary btn-sm ml-2">
                <i aria-hidden="true" class="fa fa-plus"></i> Add New
              </button>
            </router-link>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-center" v-if="loading">
            <span class="atom-spinner-wrapper">
              <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)" />
            </span>
          </div>
          <div class="col-md-12" v-else>
            <datatable v-bind="$data" class="le-datatable"></datatable>
          </div>
        </div>
      </div>
    </vuestic-widget>

    <vuestic-modal :isOpen="isOpenModalDelete" @ok="handleArchive" @cancel="closeModalDelete" okText="Delete"
      cancelText="Cancel" okClass="btn btn-danger">
      <span slot="title" class="text-danger font-weight-bold">Delete Plan</span>
      <div v-if="selectedPlan">
        Confirm you want to delete the plan <b>{{ selectedPlan.name }}</b>?
      </div>
      <div>The plan from Stripe account will be permanently deleted.</div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalLink" @ok="handleCopyLink" @cancel="closeModalLink" okText="Copy"
      cancelText="Cancel" okClass="btn btn-primary" :closeOnOk="false">
      <span slot="title" class="text-primary font-weight-bold">Plan Info</span>
      <p>Here is order link for Plan <strong>{{ selectedPlan ? selectedPlan.name : '' }}</strong></p>

      <textarea class="code" rows="4" id="orderLink" readonly>{{ orderLink }}</textarea>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalAlert" :cancelShown="false" :focus="true" @close="okModalAlert" @ok="okModalAlert"
      okText="Got It !">
      <span slot="title">Confirmation</span>
      <br />To add plans, you will need to integrate your stripe account first.
      <br />
      <br /><b>Please go to <router-link class="link" :to="{ name: 'agency.settings' }">setting page</router-link> and add
        your own stripe credential.</b>
      <br />
      <br />
      <p><i>Please contact <a class="link" href="mailto:support@ringbot.io">support@ringbot.io</a> if you have further
          questions about Stripe Integration.</i></p>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalDefaultAlert" :cancelShown="false" :focus="true"
      @ok="isOpenModalDefaultAlert = false" okText="Got It !">
      <span slot="title">Alert</span>
      <span class="text-primary font-weight-bold">Please add/set default plan for trial accounts.</span><br /><br />
      <p><i>Please contact <a class="link" href="mailto:support@ringbot.io">support@ringbot.io</a> if you have further
          questions about Stripe Integration.</i></p>
    </vuestic-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from "vuex";
import components from '../../common/tables/comps'
import IndexAction from './IndexAction'
export default {
  name: 'AgencyPlans',
  data() {
    return {
      agency: null,
      supportBackup: false,
      supportNested: true,
      HeaderSettings: false,
      tblClass: 'table-bordered',
      tblStyle: 'color: #666',
      pageSizeOptions: [10, 25, 50, 100],
      columns: (() => {
        const cols = [
          { title: 'Name', sortable: false, field: 'name', thComp: 'FilterTh', tdStyle: { fontStyle: 'normal' }, tdClass: 'center' },
          { title: 'Feature', sortable: false, field: 'features', tdComp: 'TdPlanMeta', tdStyle: { fontStyle: 'normal' } },
          { title: 'Price', sortable: false, field: 'amount', tdStyle: { fontStyle: 'normal' }, tdClass: 'center' },
          { title: 'Interval', sortable: false, field: 'interval_str', tdStyle: { fontStyle: 'normal' }, tdClass: 'center' },
          { title: 'Trial Period (Days)', sortable: false, field: 'trial_period_days', tdStyle: { fontStyle: 'normal' }, tdClass: 'center' },
          { title: 'Default', sortable: false, field: 'default', tdStyle: { fontStyle: 'normal' }, tdClass: 'center', tdComp: 'TdBoolean' },
          { title: 'Visible', sortable: false, field: 'visible', tdStyle: { fontStyle: 'normal' }, tdClass: 'center', tdComp: 'TdBoolean' },
          { title: 'Operation', tdComp: IndexAction, visible: 'true', tdClass: 'center' },
        ];
        return cols;
      })(),
      data: [],
      total: 0,
      summary: {},
      query: {
        status: 'active',
        search: null
      },
      search: null,
      xprops: {
        eventbus: new Vue(),
      },
      loading: false,
      selectedPlan: null,
      isOpenModalDelete: false,
      isOpenModalLink: false,
      isOpenModalAlert: false,
      isOpenModalDefaultAlert: false,
    }
  },

  mounted() {
    this.xprops.eventbus.$on('delete', (item) => {
      this.isOpenModalDelete = true
      this.selectedPlan = item
    })

    this.xprops.eventbus.$on('edit', (item) => {
      this.$router.push({ name: 'agency.plans.edit', params: { id: item.id } })
    })

    this.xprops.eventbus.$on('link', (item) => {
      this.isOpenModalLink = true
      this.selectedPlan = item
    })

    this.agency = this.$store.state.auth.user.agency
  },

  computed: {
    ...mapGetters("auth", {
      isMainAgency: "isMainAgency",
    }),

    user() {
      return this.$store.state.auth.user;
    },

    hasAgencyStripeEnabled() {
      return this.$store.getters['auth/hasAgencyStripeEnabled']
    },

    orderLink() {
      let domain = 'https://'
      if (this.user) {
        let agency = this.user.agency
        const whitelabel = { ...agency.whitelabel }
        const subdomain_url = agency.subdomain_url + '.' + this.$store.state.app.sitedata.whitelabel_domain;
        domain = domain + (whitelabel.domain ? whitelabel.domain : subdomain_url) + '/';
      }

      if (this.selectedPlan) {
        if (this.isMainAgency)
          domain = domain + 'b/register?plan=' + this.selectedPlan.id
        else
          domain = domain + 'business/register?plan=' + this.selectedPlan.id
      }
      return domain
    },
  },

  watch: {
    query: {
      handler() {
        this.handleQueryChange()
      },
      deep: true
    }
  },
  methods: {
    closeModalLink() {
      this.isOpenModalLink = false
    },

    handleCopyLink() {
      document.getElementById('orderLink').select()
      document.execCommand('copy')

      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
      })
    },

    closeModalAlert() {
      this.isOpenModalAlert = false
    },

    okModalAlert() {
      this.$router.push({ name: 'agency.settings' })
    },

    handleSearch() {
      this.query.search = this.search
    },

    clearSearch() {
      this.search = null
      this.query.search = null
    },

    handleArchive() {
      this.loading = true
      const param = {
        agency_id: this.agency.id,
        plan_id: this.selectedPlan.id
      }
      this.$store.dispatch('agency/deletePlan', param)
        .then(res => {
          this.closeModalDelete()
          this.handleQueryChange()
        })
        .catch(errors => {
          this.loading = false
        })
    },

    handleQueryChange() {
      this.loading = true
      let query = this.query;
      const page = query.offset ? Math.floor(query.offset / query.limit) + 1 : 1;

      const param = {
        agency_id: this.agency.id,
        ...this.query,
        page,
        per_page: query.limit,
        order: query.order,
        sort: query.sort,
      }

      this.$store.dispatch("agency/getPlans", param).then(({ data }) => {
        this.data = data.data
        this.total = data.meta.total
        if (!data.stripe_enabled) {
          this.isOpenModalAlert = true;
        } else if (!data.has_defalut_plan) {
          this.isOpenModalDefaultAlert = true
        }
        this.loading = false
      })
        .catch(error => {
          this.loading = false
        });
    },

    closeModalDelete() {
      this.isOpenModalDelete = false
      this.selectedPlan = null
    },
  }
}
</script>
<style>
.w-240 {
  width: 240px;
}

.code {
  border: none;
  background-color: #dcdbe5;
  width: 100%;
  resize: none;
  padding: 10px;
  height: 110px;
}
</style>
